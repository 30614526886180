<template>
  <div>
    <div class="border border-solid border-blue-200">
      <datatable
        :data="loans.data"
        :columns="loans.columns"
        :actions="loans.actions"
        :query="searchQuery"
        :loading="loans.loading"
        :on-click="click"
        v-model="selection"
        :ajax="true"
        :ajax-pagination="true"
        :url="`${this.$baseurl}/admin/personal/loans/reimbursed`"
        :search-field="searchField"
        ref="table"
      />
    </div>

    <modals-user
      ref="viewModal"
      :selected-user="selectedUser"
      :allow-delete="false"
    />
  </div>
</template>

<script>
export default {
  props: {
    searchQuery: {
      type: String,
      default: ''
    },
    searchField: {
      type: [HTMLInputElement],
      default: null
    }
  },
  data() {
    return {
      selection: [],
      checkmark: require('@/assets/checkmark-base.svg'),
      loans: this.$options.resource([], {
        selected: null,
        columns: [
          {
            name: 'id',
            th: 'Loan ID'
          },
          {
            name: 'name',
            th: 'User Name',
            render: loan => `${loan.user?.name} ${loan.user?.last_name || ''}`
          },
          {
            name: 'user.phone_no',
            th: 'User Phone No',
            render: loan => loan?.user?.phone_no
          },
          {
            name: 'requested_amount',
            th: 'Amount Requested',
            render: loan =>
              `₦ ${this.$options.filters.currency(loan?.requested_amount)}`
          },
          {
            name: 'interest_rate',
            th: 'Interest Rate'
          },
          {
            name: 'equity_payment.amount_paid',
            th: 'Equity Amount Paid',
            render: loan =>
              this.$options.filters.formatAmount(
                loan?.equity_payment?.amount_paid
              )
          },
          {
            name: 'equity_payment.reference',
            th: 'Payment Reference',
            render: loan => loan?.equity_payment?.reference
          },
          {
            name: 'user.profile.account_no',
            th: 'Bank Account',
            render: loan => loan.user.profile?.account_no
          },
          {
            name: 'user.profile.bank_name',
            th: 'Bank Name',
            render: loan =>
              this.$options.filters.bankName(
                loan.user.profile?.bank_name,
                this.banks
              )
          }
        ],
        actions: [
          {
            text: 'Mark As ReImbursed',
            class:
              'border-blue-500 text-blue-500 rounded-sm px-4 py-2 hover:bg-gray-200',
            action: this.markAsReImbursed
          }
        ],
        successes: [],
        failures: []
      })
    };
  },
  computed: {
    banks() {
      return this.$store.state.banks;
    },
    selectedUser() {
      return this.loans.selected?.user;
    }
  },
  mounted() {
    this.$options.listenForClick.apply(this);
  },
  methods: {
    click(loan) {
      this.loans.selected = loan;
      this.$refs.viewModal.open();
    },
    clear() {
      this.$refs.table.clearSelection();
    },
    async reloadTable() {
      await this.$refs.table.loadAjaxData();
    },
    async markAsReImbursed({ id }) {
      await this.sendRequest('admin.loans.personal.markAsReImbursed', id, {
        success: () => {
          this.$success({
            title: 'Loan Mark As ReImbursed Successfully'
          });
          this.$nextTick(() => this.reloadTable());
        },
        error: error => console.log(error)
      });
    }
  }
};
</script>
